.modal-overlap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
}
.modal-content {
    margin-left: 66px;
    margin-right: 66px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 24px 19px;
}
.modal-hero {
    color: #3751FF;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
}
.config-content {
    padding: 8px 20px;
    height: 180px;
}
.config-footer {
    text-align: right;
    margin-top: 20px;
}
.config-footer .button-base {
    width: 104px;
    height: 40px;
    padding: 0;
    background: #1C64F2;
}
.config-footer .button-light {
    background-color: #fff;
    color: #1C64F2;
    border: 1px solid #1C64F2;
    margin-right: 20px;
}
