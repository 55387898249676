.item-title-cpu-info-controlpanel{
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
    height: 19px;
}

.item-content-cpu-info-controlpanel {
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    text-align: center;
    color: #172B4D;
    height: 19px;
}

.item-cpu-info-detail-controlpanel{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    /* gap: 121px; */
    width: 399.5px;
    height: 38px;
    border: 1px solid #EFF1F1;
    border-radius: 2000px;
}

.input-form-channel-output-controlpanel {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    caret-color: #3751FF;
    background-color: #00000000;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-right-style: none;

    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #3751FF;

    /* text */
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #172B4D;

}

.input-form-channel-output-controlpanel:focus {
    outline: none;
}
.item-button-channel-output-controlpanel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background-color: #3751FF;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
}